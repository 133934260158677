import {useTranslation} from "react-i18next";

function HeaderResetPassword() {
  const { t } = useTranslation();
  return (
    <>
      <h2>Welcome back !</h2>
      <h4 className="text-left">{t("Reset Your Password")}</h4>
    </>
  );
}

export default HeaderResetPassword;
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {useTranslation, withTranslation} from "react-i18next";
import {useEffect} from "react";


interface Props {
    text?: string;
    awesomeClassIcon?: string; 
    onExportHandler?: () => void;
    onClickHandler?: () => void;
}

const StyledButton = styled(Button)({
  backgroundColor: "rgb(4, 31, 104)",
});

function ButtonExport({onExportHandler, text, awesomeClassIcon="fa-solid fa-file-arrow-down"}: Props) {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);
  
  return (
    <StyledButton 
      variant="contained"
      className="d-flex align-items-center"
      onClick={onExportHandler}>
      <i className={`${awesomeClassIcon} mr-2`}></i>
      <span style={{top: "2px", position: "relative"}}>
        {text ?? t("Export")}
      </span> 
    </StyledButton>
  );
}

export function PlainButton({onClickHandler, text=""}: Props) {
  return (
    <StyledButton 
      variant="contained"
      className="d-flex align-items-center"
      onClick={onClickHandler}>
      {text}
    </StyledButton>
  );
}
export default withTranslation("dashboard")(ButtonExport);


import Select, {MultiValue} from "react-select";
import {SelectOption} from "../../../../common/select";

interface Props {
    type: string;
    placeHolder: string;
    bootstrapCol: string;
    options: MultiValue<SelectOption>;
    selectedOptions: MultiValue<SelectOption>;
    onSelect: (type: string, selection: MultiValue<SelectOption>) => void;
}

export default function SensorSelect({options, placeHolder, bootstrapCol, type, selectedOptions, onSelect}: Props) {
  return (
    <Select
      placeholder={gettext(placeHolder)}
      isMulti={true}
      options={options}
      value={selectedOptions}
      onChange={(selection: MultiValue<SelectOption>) => onSelect(type, selection)}
      className={`col col-${bootstrapCol} pl-0`}
    />
  );
}
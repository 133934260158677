import { Mode } from "@src/common";
import { Building, Equipment } from "@src/common/api";
import {
  listResponse as storeResponse,
  selectedItems as storeSelection,
} from "@src/store/reducers/dashboard";
import { mode as storeMode } from "@src/store/reducers/metrology";
import { RootState } from "@src/store";
import "@src/scss/dashboard/index.scss";
import {createContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "usehooks-ts";
import SensorAnalysis from "../components/data/sensor-analysis";
import SensorTableTab from "../components/data/sensor-table-tab";
import { BuildingFilters, SensorsFilters, SensorsList } from "../index";
import {useTranslation} from "react-i18next";


export const BuildingContext = createContext({buildings: [], equipments: []});

export default function ComfortDashboard() {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const componentTabMapping = {
    "list": t("List"),
    "table": t("Table"),
    "analysis": t("Analysis")
  };
  type ComponentTab = keyof typeof componentTabMapping;

  const [searchParams] = useSearchParams();
  const [regions, setRegions] = useState([]);
  const [allBuildings, setAllBuildings] = useState<Building[]>();
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>();
  const [selectedEquipments, setSelectedEquipments] = useState<Equipment[]>();
  const [buildingsEquipments, setBuildingsEquipments] = useState<Equipment[]>();
  const [selectedComponent, setSelectedComponent] = useState<ComponentTab>("list");
  const { data, error } = useFetch<any>(`/comfort/api/dashboard?${searchParams}`);


  const store = useSelector((state: RootState) => state.dashboard);
  const mode = useSelector((state: RootState) => state.metrology.mode);
  const dispatch = useDispatch();
  const dispatchResponse = (data: any) =>
    dispatch(storeResponse(Object.assign({}, store.list.response, data)));
  const dispatchSelection = (data: any) =>
    dispatch(storeSelection(Object.assign({}, store.selected, data)));

  const filterEquipmentsByBuilding = (buildings: Building[]) => {
    if (!buildings) {
      return data?.equipments;
    }
    return data?.equipments.filter((e: Equipment) => {
      return buildings.find(
        (b: Building) => b.name == e.gui_informations.building
      );
    });
  };

  const updateURL = () => {
    /**
     * Update URL without re-render components.
     */
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    if (window.history.pushState) {
      window.history.pushState({ path: newUrl }, "", newUrl);
    } else {
      window.location.href = newUrl;
    }
  };

  useEffect(() => {
    const VIEWS_ALLOWED = ["analysis", "table", "list"];
    const view = searchParams.get("view");
    if (VIEWS_ALLOWED.includes(view)) {
      const componentTab = view as keyof typeof componentTabMapping;
      setSelectedComponent(componentTab);
    }
  }, []);

  useEffect(() => {
    if (!mode) {
      dispatch(storeMode(Mode.COMFORT));
    }
    if (data) {
      const buildings = data.selected_building
        ? [data.selected_building]
        : data.buildings;
      setAllBuildings(buildings);
      const _regions = buildings
        .map((building: Building) => {
          const region = building.informations?.region;
          if (!region) return "";
          return (
            region.charAt(0).toUpperCase() + region.substring(1).toLowerCase()
          ).trim();
        })
        .filter((region: string) => region?.length);

      _regions.sort();
      _regions.unshift(t("All"));
      setRegions([...new Set(_regions)]);
      setSelectedBuildings(buildings);

      const equipments = filterEquipmentsByBuilding(buildings);
      setSelectedEquipments(equipments);
      setBuildingsEquipments(equipments);
      dispatchResponse(data);
      dispatchSelection({buildings, equipments});
    }
  }, [data]);

  const getSelectedComponent = () => {
    switch (selectedComponent) {
    case "list":
      return (
        <>
          <div className="mb-5 mt-2">
            <SensorsFilters
              allEquipments={buildingsEquipments}
              onSelectFilter={(equipments: Equipment[]) =>
                setSelectedEquipments(equipments)
              }
            />
          </div>
          <div className="table-responsive position-relative mt-5">
            {selectedEquipments ? (
              <SensorsList
                buildings={selectedBuildings}
                equipments={selectedEquipments}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      );
    case "analysis":
      return <SensorAnalysis />;

    //   TODO: Use context instead,
    case "table":
      return (
        <>
          <BuildingContext.Provider value={{buildings: allBuildings, equipments: selectedEquipments}}>
            <SensorTableTab />
          </BuildingContext.Provider>
        </>
      );
    }
  };
  
  if (!data) {
    return <h1>{t("Loading")} ... </h1>;
  } else if (error) {
    return <h1>{t("Error on loading dashboard")}</h1>;
  }
  return (
    <div>
      <div className="card card-dashboard-comfort col">
        {/* <div className="card-body pb-5"> */}
        <div className="card-body">
          <BuildingFilters
            regions={regions}
            buildings={allBuildings}
            onSelectBuilding={(buildings: Building[]) => {
              const buildings_ = buildings.length ? buildings : allBuildings;
              setSelectedBuildings(buildings_);
              const equipments = filterEquipmentsByBuilding(buildings_);
              setSelectedEquipments(equipments);
              setBuildingsEquipments(equipments);
              updateURL();
              dispatchSelection({ buildings: buildings_, equipments });
            }}
          />

          <div className={"row tab-select justify-content-center"}>
            {/* <nav>
                            <Link to={`list?${searchParams}`}>Liste</Link>
                            <Link to={`table?${searchParams}`}>Tableau</Link>
                            <Link to={`analysis?${searchParams}`}>Analyse</Link>
                        </nav> */}
            {Object.keys(componentTabMapping).map((tabTitle: ComponentTab, index: number) => (
              <h2
                key={tabTitle + index}
                className={`row tab-item ${
                  selectedComponent === tabTitle ? "tab-item-active" : ""
                }`}
                onClick={() => {
                  setSelectedComponent(tabTitle);
                }}
              >
                {t(componentTabMapping[tabTitle as keyof typeof componentTabMapping])}
              </h2>
            ))}
          </div>

          {getSelectedComponent()}
        </div>
      </div>
    </div>
  );
}

import translation from "@src/common/locale/translation";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Distribution } from "../data/sensor-analysis";

interface Props {
  data: Distribution[]
}

export default function TemperatureDistributionChart({data}: Props) {
  const translateDataKeys = (distribution: Distribution[]) => {
    if (!distribution || distribution.length <= 0) return [];
    const keys = Object.keys(distribution[0]);
    return distribution.map(distribution => {
      const data: {[key: string]: any} = {};
      keys.forEach(key => {
        if(key == "_id") data[key] = distribution[key];
        else {
          const k = translation[key];
          data[k] = distribution[key];
        }
      });
      return data;
    });
  };
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={translateDataKeys(data)}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={translation["name"]} />
        <YAxis unit="%"/>
        <Tooltip formatter={(value: number) => Math.round(value)} />
        <Legend />
        <Line type="monotone" dataKey={translation["occupied"]} stroke="#8884d8" activeDot={{ r: 8 }} unit="%" />
        <Line type="monotone" dataKey={translation["unoccupied"]} stroke="#82ca9d" unit="%" />
        <Line type="monotone" dataKey={translation["weekend"]} stroke="#891296" unit="%" />
      </LineChart>
    </ResponsiveContainer>
  );
}

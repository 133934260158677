import { ListOfDetailledSensors, ListOfSensorsNames, NewSensorsData } from "@src/common";
import axios from "axios";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ReferenceArea } from "recharts";
import { 
  resetDataBySensor, 
  setDateIsChanging, 
  switchSensorDisplay, 
  updateDataBySensor, 
  setDataIsLoadingComfort } from "@src/store/reducers/metrology";
import { RootState } from "@src/store";
import { LineChart } from "./charts/line";
import Scatter from "./charts/scatter";
import { Card } from "./collapse-card";
import { remoteTemperatureDeveui } from "../index";

export default function Comfort() {
  const [params] = useSearchParams();
  const {
    thresholds,
    start,
    end,
    mode,
    sensors,
    selectedSensors,
    format,
    comfortDataBySensor,
    remoteTemperatureDisplay,
    dateIsChanging
  } = useSelector(
    ({
      metrology: {
        thresholds,
        start,
        end,
        sensors,
        selectedSensors,
        mode,
        displayFormat,
        comfortDataBySensor,
        remoteTemperatureDisplay,
        dateIsChanging
      },
    }: RootState) => ({
      thresholds,
      start,
      end,
      sensors,
      selectedSensors,
      mode,
      format: displayFormat,
      comfortDataBySensor,
      remoteTemperatureDisplay,
      dateIsChanging
    })
  );

  const dispatch = useDispatch();
  
  const getSensorsNames = (listOfSensors: ListOfDetailledSensors): ListOfSensorsNames => {
    return Object.values(listOfSensors || {})
      .flat(1)
      .reduce(
        (prev, sensor) =>
          Object.assign(prev, { [sensor.name]: sensor.color }),
        {}
      );
  };

  const getSensorsToFetch = (
    namesOfAllSensors: ListOfSensorsNames,
    namesOfSelectedSensors: ListOfSensorsNames,
  ): ListOfSensorsNames => {
    const sensorsToFetch: ListOfSensorsNames = {};
    for (const [sensorName, sensorColor] of Object.entries(namesOfAllSensors)) {
      if (namesOfSelectedSensors[sensorName]) {
        if (comfortDataBySensor[sensorName]) {
          // just activate this sensor because it already exist in comfortDataBySensor
          dispatch(switchSensorDisplay({sensorLabel: sensorName, bool: true, mode: mode}));
        }
        else {
          // register as a sensor to fetch in backend api
          sensorsToFetch[sensorName] = sensorColor;
        }
      }
      else {
        if (comfortDataBySensor[sensorName]){
          // deactivate the display of this sensor because it is not selected anymore
          dispatch(switchSensorDisplay({sensorLabel: sensorName, bool: false, mode: mode}));
        }
      }
    }
    return sensorsToFetch;
  };

  const appendEmptyDataIfSensorIsEmpty = (
    responseData: NewSensorsData[], 
    sensorsToFetch: ListOfSensorsNames, 
  ) => {
    for (const sensorName of Object.keys(sensorsToFetch)) {
      if (!responseData.map((sensor: any) => {return sensor.label;}).includes(sensorName)) {
        responseData.push({
          label: sensorName,
          color: sensorsToFetch[sensorName],
          data: []
        });
      }
    }
  };
  const namesOfAllSensors = getSensorsNames(sensors);
  const previousDelta = useRef<number>();

  useEffect(() => {
    if (!sensors) {return;}
  
    let sensorsToFetch: ListOfSensorsNames = {};
  
    const namesOfSelectedSensors = getSensorsNames(selectedSensors);
  
    if (end - start != previousDelta.current) {
      dispatch(setDateIsChanging(true));
      sensorsToFetch = namesOfSelectedSensors;
      dispatch(resetDataBySensor({mode: mode}));
    }
    else {
      sensorsToFetch = getSensorsToFetch(namesOfAllSensors, namesOfSelectedSensors);
    }
  
    const deveui_param = new URLSearchParams();
    deveui_param.set("deveui", JSON.stringify(sensorsToFetch));
    
    previousDelta.current = end - start;
  
    if (Object.keys(sensorsToFetch).length) {   
      dispatch(setDataIsLoadingComfort({value: true}));
      axios
        .get(
          `/api/v1/${mode}/series?building=${params.get(
            "building"
          )}&${deveui_param}&startDate="${moment(start).format("YYYY-MM-DD HH:mm:ss")
          }"&endDate="${moment(end).format("YYYY-MM-DD HH:mm:ss")
          }"&period="${format}"&activityHours="9-18"&types="temp,hum,co2,batterie,motion,brightness,comfort"&mode=${mode}`
        )
        .then((res) => {
          appendEmptyDataIfSensorIsEmpty(res.data, sensorsToFetch);
          dispatch(updateDataBySensor({data: res.data, mode: mode}));
        })
        .finally(() => {
          dispatch(setDataIsLoadingComfort({value: false}));
          dispatch(setDateIsChanging(false));
        });
    }
  }, [selectedSensors, mode, start, end]);


  useEffect(() => {
    if (dateIsChanging) {return;}

    if (remoteTemperatureDisplay) {
      if (!comfortDataBySensor[remoteTemperatureDeveui]) {
        dispatch(setDataIsLoadingComfort({value: true}));
        axios
          .get(
            `/api/v1/${mode}/meteo/temperature?building=${params.get(
              "building"
            )}&startDate=${moment(start).format("YYYY-MM-DD HH:mm:ss")
            }&endDate=${moment(end).format("YYYY-MM-DD HH:mm:ss")}`
          )
          .then((res) => {
            dispatch(updateDataBySensor({data: res.data, mode: mode}));
          })
          .finally(() => {dispatch(setDataIsLoadingComfort({value: false}));});
      }
      else {
        dispatch(switchSensorDisplay({sensorLabel: remoteTemperatureDeveui, bool: true, mode: mode}));
      }
    }
    else if (comfortDataBySensor[remoteTemperatureDeveui]) {
      dispatch(switchSensorDisplay({sensorLabel: remoteTemperatureDeveui, bool: false, mode: mode}));
    }
  }, [remoteTemperatureDisplay, dateIsChanging]);

  const data: any = Object.values(comfortDataBySensor).filter(sensor => sensor.display).map(sensorToDisplay => sensorToDisplay.sensor) || [];
  const comfort_winter = {
    humidity: thresholds.humidity.winter,
    temperature: thresholds.temp.winter,
  };
  const comfort_summer = {
    humidity: thresholds.humidity.summer,
    temperature: thresholds.temp.summer,
  };
  const humidity = thresholds.humidity;
  const amb = thresholds.amb;
  const temp = thresholds.temp;
  const luminosity = thresholds.luminosity;
  const period = { start, end };
  const labels: ListOfSensorsNames = Object.values(sensors || {})
    .flat(1)
    .reduce(
      (prev, sensor) =>
        Object.assign(prev, {
          [sensor.name]: sensor.gui_informations?.label || sensor.name,
        }),
      {}
    );
  labels[remoteTemperatureDeveui] = gettext("Temperature Extérieure");

  const setPeriod = (m: number, d: number, type: keyof typeof period) =>
    moment(period[type]).clone().month(m).date(d).hour(0).minute(0).seconds(0);

  const summer = {
    start: setPeriod(3, 15, "start"),
    end: setPeriod(9, 15, "end"),
  };

  const winterBeginYear = {
    start: setPeriod(9, 15, "start").subtract(1, "year"),
    end: setPeriod(3, 15, "end"),
  };

  const winterEndYear = {
    start: winterBeginYear.start.clone().add(1, "year"),
    end: winterBeginYear.end.clone().add(1, "year"),
  };

  const AreaLegend = ({ legend }: { legend: { [key: string]: string } }) => {
    return (
      <p className="text-center mr-2">
        {Object.entries(legend).map(([key, value]) => (
          <Fragment key={key + value}>
            <span
              className="mr-1"
              style={{ color: value, fontSize: "32px", verticalAlign: "sub" }}
            >
              ■
            </span>
            <span className="mr-1">{key}</span>
          </Fragment>
        ))}
      </p>
    );
  };
  const charts = [
    {
      title: gettext("Areas of hygrothermal comfort"),
      id: "scatter-hygro",
      type: "comfort",
      height: "auto",
      children: () =>
        (
          <Scatter
            XKey="temperature"
            YKey="humidity"
            yAxisUnit={gettext("Humidity (%)")}
            xAxisUnit={gettext("Temperature (°C)")}
            lines={data || []}
            chartId="comfort"
            lookupLabels={labels}
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Summer threshold")]: "rgba(245, 212, 66, 0.3)",
                  [gettext("Winter threshold")]: "rgba(106, 176, 212, 0.2)",
                }}
              />
            }
          >
            {!!comfort_winter.temperature.active &&
              comfort_winter.humidity.active && (
              <ReferenceArea
                ifOverflow="hidden"
                opacity={0.2}
                fill="#6ab0d4"
                y1={comfort_winter.humidity.min}
                y2={comfort_winter.humidity.max}
                x1={comfort_winter.temperature.min}
                x2={comfort_winter.temperature.max}
              />
            )}
            {!!comfort_summer.temperature.active &&
              comfort_summer.humidity.active && (
              <ReferenceArea
                ifOverflow="hidden"
                opacity={0.3}
                fill="#f5d442"
                y1={comfort_summer.humidity.min}
                y2={comfort_summer.humidity.max}
                x1={comfort_summer.temperature.min}
                x2={comfort_summer.temperature.max}
              />
            )}
          </Scatter>
        ),
    },
    {
      title: gettext("Ambient temperature"),
      id: "line-temp",
      type: "temperature",
      height: 415,
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            yAxisUnit={gettext("Temperature (°C)")}
            optimizations={false}
            data={data || []}
            XKey="ts"
            YKey='temperature'
            chartId="temp"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Summer threshold")]: "rgba(245, 212, 66, 0.3)",
                  [gettext("Winter threshold")]: "rgba(106, 176, 212, 0.2)",
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          >
            {temp.winter.active && (
              <ReferenceArea
                ifOverflow="hidden"
                fill="#6ab0d4"
                opacity={0.2}
                yAxisId="left"
                x1={winterBeginYear.start.unix()}
                x2={winterBeginYear.end.unix()}
                y1={temp.winter.min}
                y2={temp.winter.max}
              />
            )}
            {temp.winter.active && (
              <ReferenceArea
                ifOverflow="hidden"
                fill="#6ab0d4"
                opacity={0.2}
                yAxisId="left"
                x1={winterEndYear.start.unix()}
                x2={winterEndYear.end.unix()}
                y1={temp.winter.min}
                y2={temp.winter.max}
              />
            )}
            {temp.summer.active && (
              <ReferenceArea
                ifOverflow="hidden"
                fill="#f5d442"
                opacity={0.3}
                yAxisId="left"
                x1={summer.start.unix()}
                x2={summer.end.unix()}
                y1={temp.summer.min}
                y2={temp.summer.max}
              />
            )}
          </LineChart>
        ),
    },
    {
      title: gettext("Humidity"),
      id: "line-humidity",
      type: "humidity",
      height: 415,
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            yAxisUnit={gettext("Humidity (%)")}
            optimizations={false}
            data={data || []}
            XKey="ts"
            YKey='humidity'
            chartId="hum"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Recommended humidity level")]:
                    "rgba(189, 236, 182, 0.5)",
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          >
            {humidity.winter.active && (
              <ReferenceArea
                fill="#BDECB6"
                opacity={0.5}
                ifOverflow="hidden"
                yAxisId="left"
                x1={0}
                x2={999999999999}
                y1={humidity.winter.min}
                y2={humidity.winter.max}
              />
            )}
          </LineChart>
        ),
    },
    {
      title: gettext("Air Quality"),
      id: "line-qual",
      type: "co2",
      height: 415,
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            yAxisUnit={gettext("CO2 concentration (ppm)")}
            optimizations={false}
            data={data || []}
            XKey="ts"
            YKey='co2'
            chartId="co2"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Recommended CO2 rate")]:
                    "rgba(189, 236, 182, 0.5)",
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          >
            {amb.annual.active && (
              <ReferenceArea
                fill="#BDECB6"
                opacity={0.5}
                ifOverflow="hidden"
                yAxisId="left"
                x1={0}
                x2={999999999999}
                y1={amb.annual.min}
                y2={amb.annual.max}
              />
            )}
          </LineChart>
        ),
    },
    {
      title: gettext("Luminosity"),
      type: "brightness",
      id: "line-lum",
      height: 415,
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            yAxisUnit={gettext("Luminosity (lux)")}
            optimizations={false}
            data={data || []}
            XKey="ts"
            YKey='brightness'
            chartId="lum"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Recommended luminosity rate")]:
                    "rgba(189, 236, 182, 0.5)",
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          >
            {luminosity.luminosity.active && (
              <ReferenceArea
                fill="#BDECB6"
                opacity={0.5}
                ifOverflow="hidden"
                yAxisId="left"
                x1={0}
                x2={999999999999}
                y1={luminosity.luminosity.min}
                y2={luminosity.luminosity.max}
              />
            )}
          </LineChart>
        ),
    },
    {
      title: gettext("Movement"),
      id: "line-mov",
      type: "motion",
      height: 415,
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            optimizations={false}
            data={data || []}
            XKey="ts"
            YKey="motion"
            chartId="motion"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          />
        ),
    },
    {
      title: gettext("Battery (stack)"),
      id: "line-batt",
      height: 415,
      type: "batterie",
      children: () =>
        (
          <LineChart
            lookupLabels={labels}
            yAxisUnit={gettext("Voltage (V)")}
            data={data || []}
            XKey="ts"
            YKey='batterie'
            chartId="batterie"
            areaLegend={
              <AreaLegend
                legend={{
                  [gettext("Weekend")]: "rgba(34,139,34, 0.2)",
                }}
              />
            }
          />
        ),
    },
  ];

  return (
    <Fragment>
      {
        charts?.map(({ children, ...def }) => {
          const render = children();
          if (render) {
            return (
              <Card
                cardBodyStyle={{ height: "auto" }}
                key={def.id}
                eng={def.type}
                {...def}
              >
                {children()}
              </Card>
            );
          }
        })
      }
    </Fragment>
  );
}

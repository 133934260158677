import {useAppDispatch} from "@src/store";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";
import {resetPassword} from "@src/auth/core/usecases/reset-password.usecase";

interface ResetForm {
  password: string;
  password_confirmation: string;
}

const initResetForm = {
  defaultValues: { password: "", password_confirmation: "" },
};

export const useResetPasswordForm = () => {
  const [urlParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const {register, handleSubmit, formState} = useForm<ResetForm>(initResetForm);

  const onSubmit = async (data: ResetForm) => {
    try {
      await dispatch(resetPassword({newPassword: data.password_confirmation, urlParams})).unwrap();
      window.location.href = "/account/signin";
    } catch (e) {
      console.error(e);
    }
  };

  const validatePassword = (value: string, _: any)=> {
    const regex = [
      /[0-9]{1,}/,
      /[A-Z]{1,}/,
      /[a-z]{1,}/,
      /[()[\]{}|`~!@#$%^&*_\-+=;:'",<>./?]/
    ];
    return regex.every(test => test.test(value));
  };

  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    isSubmittable: !formState.isValid,
    passwordOption: {
      minLength: 9,
      required: true,
      validate: validatePassword
    },
    passwordConfirmationOption: {
      required: true,
      validate: (value: string, formValues: ResetForm) => value === formValues.password
    }
  };
};



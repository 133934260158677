import {createAppAsyncThunk} from "@src/store/withTypes";
import {authenticationFailed} from "@src/auth/core/store/auth";
import HttpError, {extractStatusCode} from "@src/shared/http-error";

type ResetPassword = {
  newPassword: string,
  urlParams: any,
}

export const resetPassword = createAppAsyncThunk(
  "auth/resetPassword",
  async (payload: ResetPassword, {dispatch, extra: { httpGateway }}
  ) => {
    const url = `/account/api/password/reset/?${payload.urlParams}`;
    try {
      const response = await httpGateway.post(url, {password_confirmation: payload.newPassword});
      return response.data;
    } catch (e) {
      const statusCode = extractStatusCode(e);
      dispatch(authenticationFailed(statusCode));
      throw new HttpError(statusCode, e.message, {});
    }
  }
);
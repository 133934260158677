import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "@src/store";
import {useEffect, useMemo, useRef, useState} from "react";
import {SensorFilter} from "@src/metrology/core/filters/sensors";
import {MetrologyDomainModel} from "@src/metrology/core/models/equipments";


export const useFilters = () => {
  const {t} = useTranslation();
  const sensorByType = useSelector((state: RootState) => state.metrology.sensors);
  const filter = useRef(new SensorFilter());
  const initOptions = filter.current.generateSelectOption(sensorByType?.default ?? []);
  const sensorsSelected = sensorByType?.default?.filter(
    (sensor) => filter.current.isMatchingCriteria(sensor)
  );

  return {
    options: initOptions,
    sensors: sensorByType,
    filter: filter.current,
  };
};

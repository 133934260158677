import {useTranslation} from "react-i18next";

function HeaderSignIn() {
  const { t } = useTranslation();
  return (
    <div className="mb-5 d-flex" style={{flexDirection: "column"}}>
      <h1 className="main-logo1 ml-1 mr-0 my-auto tx-28">Insight</h1>
      <br/>
      <h5 style={{marginLeft: "0.25rem"}}>{`${t("Energy")} - ${t("Comfort")}`}</h5>
    </div>
  );
}

export default HeaderSignIn;
import {useTranslation} from "react-i18next";

function PasswordInfo() {
  const { t } = useTranslation();

  return (
    <div className="card-body text-left">
      <p className="py-0 mt-1 mb-1">
        {t("Requirements for the password:")}
      </p>
      <p className="py-0 my-0">
        <small>{t("must contain at least 9 caracters")}</small>
      </p>
      <p className="py-0 my-0">
        <small>{t("must contain at least 1 digit")}</small>
      </p>
      <p className="py-0 my-0">
        <small>{t("must contain at least 1 uppercase letter")}</small>
      </p>
      <p className="py-0 my-0">
        <small>{t("must contain at least 1 lowercase")}</small>
      </p>
      <p className="py-0 my-0">
        <small>{t("must contain at least 1 special character")}</small>
      </p>
    </div>
  );
}

export default PasswordInfo;
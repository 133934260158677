import {RootState, useAppDispatch} from "@src/store";
import {useForm} from "react-hook-form";
import {forgotPassword} from "@src/auth/core/usecases/forgot-password.usecase";
import {useSelector} from "react-redux";

interface ForgotPasswordForm {
  email: string;
}

const initForgotPasswordForm = {
  defaultValues: { email: ""},
};

export const useForgotPasswordForm = () => {
  const dispatch = useAppDispatch();
  const {register, handleSubmit, formState} = useForm<ForgotPasswordForm>(initForgotPasswordForm);

  const requestStatus = useSelector(
    (state: RootState) => state.auth.forgotPassword.email.status
  );
  
  const onSubmit = async (data: ForgotPasswordForm) => {
    await dispatch(forgotPassword(data));
  };
  
  return {
    register,
    requestStatus,
    emailOptions: {required: true},
    onSubmit: handleSubmit(onSubmit),
    isSubmittable: !formState.isValid,
  };
};



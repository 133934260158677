import {createAppAsyncThunk} from "@src/store/withTypes";
import {authenticationFailed} from "@src/auth/core/store/auth";
import HttpError, {extractStatusCode} from "@src/shared/http-error";

type ForgotPasswordForm = {
  email: string
}

export const forgotPassword = createAppAsyncThunk(
  "auth/forgotPassword",
  async (payload: ForgotPasswordForm, {dispatch, extra: { httpGateway }}
  ) => {
    const url = "/account/api/password/forgot/";
    try {
      const response = await httpGateway.post(url, payload);
      return response.data;
    } catch (e) {
      const statusCode = extractStatusCode(e);
      dispatch(authenticationFailed(statusCode));
      throw new HttpError(statusCode, e.message, {});
    }
  }
);
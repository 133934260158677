import {createSlice} from "@reduxjs/toolkit";
import {signin} from "@src/auth/core/usecases/signin.usecase";
import {AuthDomainModel} from "@src/auth/core/models/auth.domain-model";
import {resetPassword} from "@src/auth/core/usecases/reset-password.usecase";
import {getCsrfToken} from "@src/auth/core/usecases/crsf-token.usecase";
import {forgotPassword} from "@src/auth/core/usecases/forgot-password.usecase";


const initialState: AuthDomainModel.State = {
  signin: {
    status: "idle",
    statusCode: null,
    error: null
  },
  resetPassword: {
    status: "idle",
    statusCode: null,
    error: null
  },
  forgotPassword: {
    token: {
      status: "idle",
      statusCode: null,
      error: null 
    },
    email: {
      status: "idle",
      statusCode: null,
      error: null
    }
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticationFailed(state, action) {
      state.signin.statusCode = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signin.pending, (state, _) => {
        state.signin.status = "pending";
        state.signin.error = null;
        state.signin.statusCode = null;
      })
      .addCase(signin.fulfilled, (state, _) => {
        state.signin.status = "succeeded";
      })
      .addCase(signin.rejected, (state, action) => {
        state.signin.status = "rejected";
        state.signin.error = action.error.message ?? "Unknown Error";
      })
      .addCase(resetPassword.pending, (state, _) => {
        state.resetPassword.status = "pending";
        state.resetPassword.error = null;
        state.resetPassword.statusCode = null;
      })
      .addCase(resetPassword.fulfilled, (state, _) => {
        state.resetPassword.status = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.status = "rejected";
        state.resetPassword.error = action.error.message ?? "Unknown Error";
      })
      .addCase(getCsrfToken.pending, (state, _) => {
        state.forgotPassword.token.status = "pending";
        state.forgotPassword.token.error = null;
        state.forgotPassword.token.statusCode = null;
      })
      .addCase(getCsrfToken.fulfilled, (state, _) => {
        state.forgotPassword.token.status = "succeeded";
      })
      .addCase(getCsrfToken.rejected, (state, action) => {
        state.forgotPassword.token.status = "rejected";
        state.forgotPassword.token.error = action.error.message ?? "Unknown Error";
      })
      .addCase(forgotPassword.pending, (state, _) => {
        state.forgotPassword.email.status = "pending";
        state.forgotPassword.email.error = null;
        state.forgotPassword.email.statusCode = null;
      })
      .addCase(forgotPassword.fulfilled, (state, _) => {
        state.forgotPassword.email.status = "succeeded";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPassword.email.status = "rejected";
        state.forgotPassword.email.error = action.error.message ?? "Unknown Error";
      });
  }
});

export const {authenticationFailed} = authSlice.actions;
export default authSlice.reducer;
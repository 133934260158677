export enum Mode {
  ENERGY = "energy",
  COMFORT = "comfort",
}

export interface Options {
  remote_temperature: boolean;
  global_comfort: boolean;
  comfort: boolean;
  comfort_winter: boolean;
  comfort_summer: boolean;
}

export interface Thresholds {
  temp: {
    winter: { min: number; max: number; active: boolean };
    summer: { min: number; max: number; active: boolean };
  };
  humidity: {
    winter: { min: number; max: number; active: boolean };
    summer: { min: number; max: number; active: boolean };
  };
  amb: { annual: { min: number; max: number; active: boolean } };
  luminosity: { luminosity: { min: number; max: number; active: boolean } };
}

export type SensorInformation = {
  label: string;
  type: string;
  etage?: string;
  zone?: string;
};

export interface Sensor {
  name: string;
  gui_informations: SensorInformation;
  color?: string;
}

export interface ListOfDetailledSensors {
  [typeOfSensor: string]: Sensor[]
}

export interface ListOfSensorsNames {
  [sensorName: string]: string
}

export type MetrologyResponse = MetrologyData;

export type MetrologyDataType = [
  "batterie",
  "brightness",
  "co2",
  "hum",
  "motion",
  "temp",
  "cold",
  "hot",
  "elec",
  "capt"
];

export interface SensorsData {
  data: Data[];
  lines: Line[]
}

export interface Data {
  [prop: string]: any;
  date: number;
}

export interface Line {
  key: string;
  color: string;
}

export interface ComfortDataType {
  key: string;
  color: string;
  data: ComfortData[];
}

export interface ComfortData {
  data: { x: number; y: number; date: number; key: string };
}

export interface EnergySensorValue {
  date: number;
  label: string;
  value: number;
  value_?: number;
}

export interface ComfortSensorData {
  ts: number;
  label: string;
  batterie?: number;
  brightness?: number;
  co2?: number;
  humidity?: number;
  motion?: number;
  temperature?: number;
}

export interface NewSensorsData {
  label?: string;
  deveui?: string;
  color: string;
  data: EnergySensorValue[] | ComfortSensorData[];
}

export interface EnergySensorData {
  [label: string]: NewSensorsData;
}

export type MetrologyAllData = {
  cold?: EnergySensorData;
  hot?: EnergySensorData;
  elec?: EnergySensorData;
  capt?: EnergySensorData;
}





export interface MetrologyData {
  comfort?: ComfortDataType;
  batterie?: SensorsData;
  brightness?: SensorsData;
  co2?: SensorsData;
  hum?: SensorsData;
  motion?: SensorsData;
  temp?: SensorsData;
  cold?: NewSensorsData;
  hot?: NewSensorsData;
  elec?: NewSensorsData;
  capt?: NewSensorsData;
}

export interface MetrologyEnergyLoading {
  comfort?: boolean;
  batterie?: boolean;
  brightness?: boolean;
  co2?: boolean;
  humidity?: boolean;
  motion?: boolean;
  temperature?: boolean;
  cold?: boolean;
  hot?: boolean;
  elec?: boolean;
  capt?: boolean;
  [key: string]: boolean;
}



export interface DataBySensor {
  display: boolean,
  sensor: NewSensorsData
}

export interface ListComfortDataBySensor {
  [label: string]: DataBySensor
}

export interface ListEnergyDataBySensor {
  cold?: ListComfortDataBySensor;
  hot?: ListComfortDataBySensor;
  elec?: ListComfortDataBySensor;
  capt?: ListComfortDataBySensor;
}

export interface actionDispatch {
  payload: {
    data?: NewSensorsData[]
    mode?: string
    eng?: "elec" | "capt" | "hot" | "cold" 
    sensorLabel?: string
    bool?: boolean
  }
}
export interface MetrologyOpenChart {
  comfort?: boolean;
  batterie?: boolean;
  brightness?: boolean;
  co2?: boolean;
  humidity?: boolean;
  motion?: boolean;
  temperature?: boolean;
  cold?: boolean;
  hot?: boolean;
  elec?: boolean;
  capt?: boolean; 
}
import {MetrologyDomainModel} from "@src/metrology/core/models/equipments";
import {removeDuplication} from "@src/common/utils";

export class SensorFilter {
  constructor(
    public etages: string[] = [],
    public types: string[] = [],
    public zones: string[] = [],
    public tenants: string[] = []
  ) {}

  isMatchingCriteria(sensor: MetrologyDomainModel.Equipment): boolean {
    const matchesEtage = this.etages.length === 0 || this.etages.includes(sensor.gui_informations.etage);
    const matchesType = this.types.length === 0 || this.types.includes(sensor.gui_informations.type);
    const matchesZone = this.zones.length === 0 || this.zones.includes(sensor.gui_informations.zone);
    const matchesTenant = this.tenants.length === 0 || this.tenants.includes(sensor.gui_informations.tenant);

    return matchesEtage && matchesType && matchesZone && matchesTenant;
  }

  generateSelectOption(sensors: MetrologyDomainModel.Equipment[]) {
    const tmpFilters: MetrologyDomainModel.SensorOptions = {
      etages: [],
      tenants: [],
      types: [],
      zones: []
    };

    for (const sensor of sensors) {
      const {etage, zone, type, tenant} = sensor.gui_informations;
      if (etage) {
        tmpFilters.etages.push({label: etage, value: etage, color: "", type: "etage"});
      }
      if (zone) {
        tmpFilters.zones.push({label: zone, value: zone, color: "", type: "zone"});
      }
      if (type) {
        tmpFilters.types.push({label: type, value: type, color: "", type: "type"});
      }
      if (tenant) {
        tmpFilters.tenants.push({label: tenant, value: tenant, color: "", type: "tenant"});
      }
    }
    const filterOption: MetrologyDomainModel.SensorOptions = {
      etages: removeDuplication(tmpFilters.etages),
      zones: removeDuplication(tmpFilters.zones),
      types: removeDuplication(tmpFilters.types),
      tenants: removeDuplication(tmpFilters.tenants),
    };
    return filterOption;
  }

  update(values: string[], type: string) {
    const key = `${type}s` as "etages" | "zones" | "types" | "tenants";
    this[key] = [...new Set(values)];
  }
}

import {Provider} from "react-redux";
import {app} from "@src/app/main";
import {DependenciesProvider} from "@src/app/react/DependenciesProvider";
import {BrowserRouter} from "react-router-dom";
import Router from "@src/routes";

export function AppWrapper() {
  return (
    <Provider store={app.store}>
      <DependenciesProvider dependencies={app.dependencies}>
        <BrowserRouter>
          <Router/>
        </BrowserRouter>
      </DependenciesProvider>
    </Provider>
  );
}

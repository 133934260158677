import {SelectOption} from "./select";
import type { Units } from "@src/dashboards/comfort/components/data/unit-types";
import {MetrologyDomainModel} from "@src/metrology/core/models/equipments";

export function removeDuplication(array: SelectOption[] | MetrologyDomainModel.SensorOption[]) {
  return array.filter((element, index, self) =>
    index === self.findIndex((option) => option.label === element.label)
  );
}

export const isSummerSeason = (date?: Date) => {
  if (!date) {
    date = new Date();
  }
  const startSummer = new Date(date.getFullYear(), 3, 14);
  const endSummer = new Date(date.getFullYear(), 9, 15);
  return date >= startSummer && date <= endSummer;
};

export const isWeekend = (date: Date) => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0; // 6 == Saturday and 0 == Sunday
};

export const isOfficeOccupied = (date: Date) => {
  const hour = date.getHours();
  const isDaytime = hour < 20 && hour >= 7;
  const isWeekday = !isWeekend(date);

  return isDaytime && isWeekday;
};

export const isTemperatureOutOfRange = (temperature: number, seasonIsSummer: boolean, hourIsInOfficeHour: boolean) => {
  const TEMPERATURE_THRESHOLD: any = {
    summer: {occupied: {min: 20, max: 26}, unoccupied: {min: -99, max: 99}},
    winter: {occupied: {min: 19, max: 21}, unoccupied: {min: 16, max: 20}}
  };
  const occupancyState = hourIsInOfficeHour ? "occupied" :  "unoccupied";

  const isTemperatureOutsideSummerComfortRange = (
    temperature < TEMPERATURE_THRESHOLD.summer[occupancyState].min ||
        temperature > TEMPERATURE_THRESHOLD.summer[occupancyState].max
  );
  const isTemperatureOutsideWinterComfortRange = (
    temperature > TEMPERATURE_THRESHOLD.winter[occupancyState].max ||
        temperature < TEMPERATURE_THRESHOLD.winter[occupancyState].min
  );
    
  const isOutSummer = seasonIsSummer && isTemperatureOutsideSummerComfortRange;
  const isOutWinter = !seasonIsSummer && isTemperatureOutsideWinterComfortRange;
  return (isOutSummer || isOutWinter);
};

export const isCo2LevelOutOfRange = (co2: number) => {
  return co2 > 1100 || co2 <= 0;
};

export const hasMetricReachLimit = (unit: Units, value?: number) => {
  if (value === null) return true;

  const now = new Date();
  const isSummerSeasonNow = isSummerSeason(now);
  const isOfficeOccupiedNow = isOfficeOccupied(now);

  switch (unit) {
  case "degree":
    return isTemperatureOutOfRange(
      value, isSummerSeasonNow, isOfficeOccupiedNow
    );
  case "partsPerMillion":
    return isCo2LevelOutOfRange(value);
  default:
    return false;
  }
};

export const updateURL = (params: URLSearchParams) => {
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  if (window.history.pushState) {
    window.history.pushState({path: newUrl}, "", newUrl);
  } else {
    window.location.href = newUrl;
  }
};

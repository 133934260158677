import { storeData } from "@src/store/reducers/costic";
import { RootState } from "@src/store";
import axios from "axios";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function UploadBox() {
  const [files, setFile] = useState<FileList>(null);
  const dispatch = useDispatch();


  const submit = (e: FormEvent) => {
    e.preventDefault();
    if (files) {
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }
      axios.post("/energy/costic/upload", formData)
        .then((response) => dispatch(storeData(response.data.monthly_df)));
    }
  };

  const data = useSelector<RootState>((RootState) => RootState.costic.data);

  const [isLoading, setIsLoading] = useState<number>(0);

  const exportToDatabase = () => {
    setIsLoading(1);
    axios.post("/energy/costic/upload/export-to-db", data)
      .finally(() => {
        setIsLoading(2);
      });
  };
  const refreshPage = () => {
    window.location.reload();
  };

  let buttonContent;
  if (isLoading==0 && data) {
    buttonContent = <div className="col-xl col-lg col-md col-sm col text-center">
      <button onClick={exportToDatabase} className="btn btn-primary">EXPORT TO DATABSE</button>
    </div >;
  } else if (isLoading==1 && data) {
    buttonContent = <div className="col-xl col-lg col-md col-sm col text-center">
      <button onClick={exportToDatabase} className="btn btn-primary"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> EXPORTING</button>
    </div>;
  } else if (isLoading==2 && data)  {
    buttonContent = <div className="col-xl col-lg col-md col-sm col text-center">
      <button onClick={refreshPage} className="btn btn-success">EXPORTING COMPLETED <span>&#10003;</span> <br></br> (click here to refresh)</button>
    </div>;
  }  else {
    buttonContent = <div className="col-xl col-lg col-md col-sm col text-center">
      <button type="submit" className="btn btn-secondary">Load DJU files</button>
    </div>;
  }

  return (
    <div className="container-fluid">
      <div className="row row-sm">
        <div className="col-xl-12">
          <div className="card text-center">
            <div className="card-body">
              <form onSubmit={submit}>
                <div className="form-group">
                  <input type="file" onChange={e => setFile(e.target.files)} accept=".csv,.xls" className="dropify" id="files" name="files" data-max-file-size="3M" multiple />
                </div>
                <div className="row">
                  {buttonContent}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
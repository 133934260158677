import {createAppAsyncThunk} from "@src/store/withTypes";
import {authenticationFailed} from "@src/auth/core/store/auth";
import HttpError, {extractStatusCode} from "@src/shared/http-error";

type Credentials = {
  email: string;
  password: string;
}

export const signin = createAppAsyncThunk("auth/signin", async (credentials: Credentials, {dispatch, extra: { httpGateway }}) => {
  const url = "/account/api/signin/";
  try {
    const response = await httpGateway.post(url, credentials);
    return response.data;
  } catch (e) {
    const statusCode = extractStatusCode(e);
    dispatch(authenticationFailed(statusCode));
    throw new HttpError(statusCode, e.message, {});
  }
});
import {createAppAsyncThunk} from "@src/store/withTypes";
import {authenticationFailed} from "@src/auth/core/store/auth";
import HttpError, {extractStatusCode} from "@src/shared/http-error";

export const getCsrfToken = createAppAsyncThunk(
  "auth/getCsrfTokens",
  async (_: any, {dispatch, extra: { httpGateway }}) => {
    const url = "/csrf/";
    try {
      const response = await httpGateway.get<string>(url);
      return response.data;
    } catch (e) {
      const statusCode = extractStatusCode(e);
      dispatch(authenticationFailed(statusCode));
      throw new HttpError(statusCode, e.message, {});
    }
  });
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { Building, Equipment } from "../../common/api";

// import { MetrologyData, MetrologyResponse, Mode, Sensor, Thresholds } from "../../common";

const initialState: DashboardContext = {
  analysis: {
    repartitionTemperature: [],
    averageTemperature: {
      step: "",
      repartitions: []
    },
    filter: {
      period: {
        start: moment().subtract(7, "days").valueOf(), 
        end: moment().valueOf()
      },
      step: 2,
      type: "temperature",
      sensors: []
    }
  },
  list: {
    response: {
      equipments: [],
      buildings: [],
      selected_building: null,
      building_count: 0,
    },
    filter: {
      type: "",
      buildings: [],
      equipments: []
    },
  },
  table: {
    filter: {
      equipments: [],
      types: [],
    },
    displaying: "temperature",
    equipments: [],
    types: [],
    error: "",
  },
  selected: {
    buildings: [],
    equipments: []
  }
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    analysisFilter(state, action) {
      state.analysis.filter = action.payload;
    },
    analysisAvgTemperature(state, action) {
      state.analysis.averageTemperature = action.payload;
    },
    listFilter(state, action) {
      state.list.filter = action.payload;
    },
    listResponse(state, action) {
      state.list.response = action.payload;
    },
    selectedItems(state, action) {
      state.selected = action.payload;
    },
    table(state, action) {
      state.table = action.payload;
    },
    tableFilter(state, action) {
      state.table.filter = action.payload;
    },
    setTableError(state, action) {
      state.table.error = action.payload;
    },
    tableDisplaying(state, action) {
      state.table.displaying = action.payload;
    },
  },
});

export const {
  analysisFilter, analysisAvgTemperature,
  listFilter, listResponse, selectedItems, 
  table, tableFilter, tableDisplaying,
  setTableError
} = dashboardSlice.actions;

export default dashboardSlice.reducer;


interface Period {
  start: number;
  end: number;
}

export interface Filter {
  period: Period
  step: number;
  type: string;
  sensors: string[];
}

interface RepartitionTemperature {
  step?: number;
  occupied: number;
  unoccupied: number;
  weekend: number;
}

interface AvegerageTemperature {
  step: string;
  repartitions: RepartitionTemperature[];
}

interface AnalysisTab {
  filter: Filter;
  repartitionTemperature: RepartitionTemperature[];
  averageTemperature: AvegerageTemperature;
}

interface ResponseListTab {
  equipments: Equipment[];
  buildings: Building[];
  selected_building: Building;
  building_count: number;
}

interface FilterListTab {
  type: string,
  buildings: Building[];
  equipments: Equipment[];
}

interface ListTab {
  response: ResponseListTab;
  filter: FilterListTab;
}

interface FilterTable {
  types: string[];
  equipments: Equipment[];
}

interface TableTab {
  filter: FilterTable;
  displaying: string;
  equipments: Equipment[];
  types: string[];
  error: string;
}

interface Selection {
  buildings: Building[];
  equipments: Equipment[];
}

interface DashboardContext {
  selected: Selection,
  analysis: AnalysisTab,
  list: ListTab,
  table: TableTab
}
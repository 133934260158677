import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "@src/app/french_translations";
import dashboardTranslation from "@src/app/locales/fr/dashboard";

const resources = {
  fr: {
    metrology: translations,
    dashboard: dashboardTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    ns: ["metrology", "dashboard"],
    defaultNS: "metrology",
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
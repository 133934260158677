import moment, { Moment } from "moment-timezone";

interface Props {
    date: number;
    timezone: string;
}

export default function SensorState({date, timezone}: Props) {
  let lastStateDate = moment.unix(date).tz(timezone);
  if (timezone === "Europe/Paris") {
    lastStateDate = moment.unix(date).tz("UTC");
  }

  const now = moment();

  let pastille = "pastille ";
  const hourDelta = Math.abs(lastStateDate.diff(now, "hours"));

  if (hourDelta <= 12) {
    pastille += "green";
  } else if (hourDelta <= 24) {
    pastille += "yellow";
  } else if (hourDelta <= 48) {
    pastille += "orange";
  } else {
    pastille += "red";
  }

  const parseDateTime = (datetime: Moment, type: string, show_when_invalid=true) => {
    let stringDatetime: string;
    const lang = document.documentElement.lang;
    const format = lang == "fr" ? "DD MMM YYYY" : "YYYY MMM DD";
    switch (type) {
    case "date":
      stringDatetime = datetime.locale(lang).format(format);
      break;
    case "time":
      stringDatetime = datetime.format("HH:mm");
      break;
    }
    const isInvalid = stringDatetime === "Invalid Date";
    if (isInvalid && show_when_invalid) {
      return gettext("Date Invalide");
    } else if (isInvalid && !show_when_invalid) {
      return ;
    }
    return stringDatetime;
  };

  return (
    <>
      <span>{parseDateTime(lastStateDate, "date")}</span>
      <span>{parseDateTime(lastStateDate, "time", false)}</span>
      <span className={pastille}></span>
    </>
  );
}
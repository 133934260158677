const translation: {[key: string]: string} = {
  name: gettext("Nom"),
  occupied: gettext("Occupation"),
  unoccupied: gettext("Innoccupation"),
  weekend: gettext("Weekend"),
  average_temperature_curves: gettext("Température moyenne des 3 courbes"),
  electricity: gettext("Électricité"),
  cold: gettext("Réseau de froid"),
};

export default translation;
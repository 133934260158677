export const units = {
  degree: "°C",
  partsPerMillion: "ppm"
};

export const unitsPrecision = {
  degree: 1,
  partsPerMillion: 0,
};

export type Units = keyof typeof units;

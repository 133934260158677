import {useTranslation} from "react-i18next";

export default function ResetPasswordFooter() {
  const {t} = useTranslation();
  return (
    <div className="main-signup-footer mg-t-20">
      <p>
        {t("Already have an account ?")}
        <a href="/account/signin">{t("Sign In")}</a>
      </p>
    </div>
  );
}
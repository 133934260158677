import {Equipment} from "@src/common/api";
import DateRangePicker from "@src/common/daterangepicker";
import {SelectOption} from "@src/common/select";
import {removeDuplication} from "@src/common/utils";
import {analysisAvgTemperature as storeAvgTemp, analysisFilter as storeFilter} from "@src/store/reducers/dashboard";
import {RootState} from "@src/store";
import moment, {Moment} from "moment";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import Select, {MultiValue} from "react-select";
import {PlainButton} from "@src/dashboards/comfort/components/buttons/button-export";
import { debounce } from "lodash";
import {useTranslation} from "react-i18next";

const fakeOptions = ["Temperature", "Qualité d'air", "Hygrométrie", "Luminosité", "Son", "Batterie"];

function createOption(label: string, value?: string, type?: string, color?: string) {
  if (!value) {
    value = label;
  }
  return {label, value, type, color};
}

export default function () {
  const selection = useSelector((state: RootState) => state.dashboard.selected);
  const analysis = useSelector(
    (state: RootState) => state.dashboard.analysis
  );

  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const [params] = useSearchParams({start: null, end: null});

  const dispatch = useDispatch();
  const dispatchFilter = (data: any) => dispatch(storeFilter(
    Object.assign({}, analysis.filter, data))
  );
  const dispatchAvgTemp = (data: any) => dispatch(storeAvgTemp(
    Object.assign({}, analysis.averageTemperature, data))
  );

  const createOptionFromEquipment = (equipments: Equipment[], field = "zone") => {
    return removeDuplication(
      equipments.map(e => createOption(e.gui_informations[field]))
    ).filter(o => !!o.label);
  };

  const [dataOptions, setDataOption] = useState<MultiValue<SelectOption>>();
  const [dataOptionSelected, setDataOptionSelected] = useState<SelectOption>();

  const [zoneOptions, setZoneOption] = useState<MultiValue<SelectOption>>([]);
  const [zoneOptionSelected, setZoneOptionSelected] = useState<MultiValue<SelectOption>>([]);

  const [roomTypeOptions, setRoomTypeOption] = useState<MultiValue<SelectOption>>([]);
  const [roomTypeOptionsSelected, setRoomTypeOptionsSelected] = useState<MultiValue<SelectOption>>([]);

  const [floorOptions, setFloorOption] = useState<MultiValue<SelectOption>>([]);
  const [floorOptionsSelected, setFloorOptionsSelected] = useState<MultiValue<SelectOption>>([]);

  const [tenantOptions, setTenantOption] = useState<MultiValue<SelectOption>>([]);
  const [tenantOptionsSelected, setTenantOptionsSelected] = useState<MultiValue<SelectOption>>([]);

  const [sensorOptions, setSensorOptions] = useState<MultiValue<SelectOption>>();
  const [sensorOptionSelected, setSensorOptionSelected] = useState<MultiValue<SelectOption>>();

  const getAvgStep = (date: Moment, date2: Moment) => {
    const diffInDay = Math.abs(date.diff(date2, "days"));
    return diffInDay <= 15 ? "daily" : "monthly";
  };

  useEffect(() => {
    const startDate = params.get("start");
    const endDate = params.get("end");

    if (startDate === "null" || endDate === "null") return;

    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");
    dispatchFilter({period: {start: start.valueOf(), end: end.valueOf()}});
    dispatchAvgTemp({step: getAvgStep(start, end)});
  }, []);

  const getSensorsByLabel = (options: MultiValue<SelectOption>) => {
    const sensors: string[] = [];
    options.forEach(option => {
      const equipment = selection.equipments.find(e => e.gui_informations.label === option.value);
      if (equipment) sensors.push(equipment.name);
    });
    return sensors;
  };
    
  const sortByAlpha = (a: any, b: any) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    const zOptions = createOptionFromEquipment(selection.equipments);
    zOptions.sort(sortByAlpha);
    const rOptions = createOptionFromEquipment(selection.equipments, "type");
    rOptions.sort(sortByAlpha);
    const fOptions = createOptionFromEquipment(selection.equipments, "etage");
    fOptions.sort(sortByAlpha);
    const tOptions = createOptionFromEquipment(selection.equipments, "tenant");
    tOptions.sort(sortByAlpha);
    const dOptions = fakeOptions.map(o => createOption(o, o?.toLowerCase()));
    dOptions.sort(sortByAlpha);
    const sOptions = createOptionFromEquipment(selection.equipments, "label");
    sOptions.sort(sortByAlpha);
    setZoneOption(zOptions as SelectOption[]);
    setRoomTypeOption(rOptions as SelectOption[]);
    setFloorOption(fOptions as SelectOption[]);
    setTenantOption(tOptions as SelectOption[]);
    setDataOption(dOptions);
    setSensorOptions(sOptions as SelectOption[]);
    setDataOptionSelected(dOptions[0] as SelectOption);
    setSensorOptionSelected([sOptions[0]] as SelectOption[]);

    const sensors = getSensorsByLabel(sOptions as SelectOption[]);
    dispatchFilter({zones: [], sensors: [sensors[0]]});
  }, [selection]);

  const selectAllSensors = () => {
    const optionValues = sensorOptions.map(option => option.value);
    const sensors = selection.equipments.filter(
      equipment => optionValues.includes(equipment.gui_informations.label)
    );
    const options = createOptionFromEquipment(sensors, "label");
    setSensorOptionSelected(options as SelectOption[]);
    dispatchFilter({sensors: sensors.map(sensor => sensor.name)});
  };

  const unselectAllSensors = () => {
    setSensorOptionSelected([]);
    dispatchFilter({sensors: []});
  };

  const setSensorOptionsByField = (optionValues: string[], optionType:string) => {
    const sensorsByField = selection.equipments.filter(equipment => {
      const zoneValues = optionType == "zone" ? optionValues : zoneOptionSelected.map(o => o.value);
      const typeValues = optionType == "type" ? optionValues : roomTypeOptionsSelected.map(o => o.value);
      const floorValues = optionType == "etage" ? optionValues : floorOptionsSelected.map(o => o.value);
      const tenantValues = optionType == "tenant" ? optionValues : tenantOptionsSelected.map(o => o.value);
      const {zone, type, etage, tenant} =  equipment.gui_informations;

      if (zoneValues.length > 0 && !zoneValues.includes(zone)) {
        return false;
      }
      if (typeValues.length > 0 && !typeValues.includes(type)) {
        return false;
      }
      if (floorValues.length > 0 && !floorValues.includes(etage)) {
        return false;
      }
      if (tenantValues.length > 0 && !tenantValues.includes(tenant)) {
        return false;
      }
      return true;
    });

    const newOptionsAvailable = createOptionFromEquipment(sensorsByField, "label");
    setSensorOptions(newOptionsAvailable as SelectOption[]);
    const newOptionSelected = sensorOptionSelected.filter(option => {
      const sensor = sensorsByField.find(s => s.gui_informations.label === option.value);
      return !!sensor;
    });
    setSensorOptionSelected(newOptionSelected);
  };

  const onZoneChanged = (options: MultiValue<SelectOption>) => {
    const optionValues = options.map(option => option.value);
    setSensorOptionsByField(optionValues, "zone");
    setZoneOptionSelected(options);
  };

  const onRoomChanged = (options: MultiValue<SelectOption>) => {
    const optionValues = options.map(option => option.value);
    setSensorOptionsByField(optionValues, "type");
    setRoomTypeOptionsSelected(options);
  };

  const onFloorChanged = (options: MultiValue<SelectOption>) => {
    const optionValues = options.map(option => option.value);
    setSensorOptionsByField(optionValues, "etage");
    setFloorOptionsSelected(options);
  };

  const onTenantChanged = (options: MultiValue<SelectOption>) => {
    const optionValues = options.map(option => option.value);
    setSensorOptionsByField(optionValues, "tenant");
    setTenantOptionsSelected(options);
  };
    
  const onStepChange = (event: any) => {
    dispatchFilter({step: +event.target.value});
  };

  const debouncedInputChange = debounce(onStepChange, 500); // 500ms delay

  return (
    <div className="col col-3 card sensor-analysis-filter-container">
      <div className="card-body">
        <h2 className="text-center">{t("Filters")}</h2>
        <div className="form-group">
          <label htmlFor="period">{t("Period")}</label>
          <div id="period-filter">
            <DateRangePicker
              id="comfort-filter-date"
              start={moment(analysis.filter.period.start)}
              end={moment(analysis.filter.period.end)}
              onChange={(start, end) => {
                dispatchFilter({period: {start, end}});
                dispatchAvgTemp(
                  {step: getAvgStep(moment(start), moment(end))}
                );
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label
            htmlFor="data-type">{t("Data Type")}</label>
          <Select
            placeholder={t("All types")}
            options={dataOptions}
            value={dataOptionSelected}
            isDisabled={true}
            onChange={(option) => {
              setDataOptionSelected(option);
              dispatchFilter({type: option.value});
            }}
            className=""
          />
        </div>
        <div className="form-group">
          <label htmlFor="step" className="d-flex align-items-center">
            {t("Intervalle") + "(°C)"} <i className="fa-solid fa-circle-info ml-2" title="1°C minimum"></i>
          </label>
          <input type="number" id="step" className="form-control"
            min="1" defaultValue={2}
            onChange={debouncedInputChange}/>
        </div>
        <div className="form-group">
          <label>{t("Zone")}</label>
          <Select
            placeholder={t("All zones")}
            isMulti={true}
            options={zoneOptions}
            value={zoneOptionSelected}
            onChange={onZoneChanged}
            className="pl-0"
          />
        </div>
        <div className="form-group">
          <label>{t("Room type")}</label>
          <Select
            placeholder={t("All rooms")}
            isMulti={true}
            options={roomTypeOptions}
            value={roomTypeOptionsSelected}
            onChange={onRoomChanged}
            className="pl-0"
          />
        </div>
        <div className="form-group">
          <label>{t("Floor")}</label>
          <Select
            placeholder={t("All floors")}
            isMulti={true}
            options={floorOptions}
            value={floorOptionsSelected}
            onChange={onFloorChanged}
            className="pl-0"
          />
        </div>
        <div className="form-group">
          <label>{t("Tenant")}</label>
          <Select
            placeholder={t("All tenants")}
            isMulti={true}
            options={tenantOptions}
            value={tenantOptionsSelected}
            onChange={onTenantChanged}
            className="pl-0"
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="category">{t("Sensors selection")}</label>
          <Select
            placeholder={t("Select")}
            isMulti={true}
            options={sensorOptions}
            value={sensorOptionSelected}
            onChange={(options) => {
              setSensorOptionSelected(options);
              const sensors = getSensorsByLabel(options);
              dispatchFilter({sensors});
            }}
            styles={{
              // Define style for the Select himself
              control: (provided) => ({
                ...provided,
                maxHeight: "250px",
                overflow: "scroll",
              }),
            }}
            className="pl-0"
          />
        </div>
        <div className="form-group sensor-analysis-buttons">
          <PlainButton onClickHandler={selectAllSensors}
            text={t("Select all")}/>
          <PlainButton onClickHandler={unselectAllSensors}
            text={t("Unselect all")}/>
        </div>
      </div>
    </div>
  );
}
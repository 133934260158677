import Layout from "@src/auth/react/sections/Layout";
import {useForgotPasswordPage} from "@src/auth/react/pages/use-forgot-password-page";
import {ForgotPasswordForm} from "@src/auth/react/sections/form/ForgotPasswordForm";

export function ForgotPasswordPage() {
  const presenter = useForgotPasswordPage();
  const {t} = presenter;

  return (
    <Layout>
      <div className="main-card-signin d-md-flex bg-white">
        <div className="wd-100p">
          <div className="main-signin-header">
            <h2>{t("Forgot Password")}</h2>
            {
              presenter.csrfToken
                ?
                <ForgotPasswordForm csrfToken={presenter.csrfToken} />
                :
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
            }
            {
              ["succeeded", "rejected"].includes(presenter.emailStatus)
                ?
                <div className='alert alert-info'>
                  {t("If this email exists, we have sent an email to him just now")}
                </div>
                :
                ""
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}

import moment from "moment";
import { useState } from "react";
import { CartesianGrid, Scatter as RScatter, ReferenceArea, ResponsiveContainer, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SensorsData } from "../../../common";
import DropdownDownload from "./dropdown-download";

export default function Scatter({ data, XKey, YKey, lines, chartId, ...props }: Props) {
  const [X, setX] = useState<any[]>([]);
  const [Y, setY] = useState<any[]>([]);
  const [left, setLeft] = useState<boolean>(false);
  const [right, setRight] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState([]);
  const zoom = () => {
    if (left && right) {
      const arrayX = X[0] > X[1] ? [X[1], X[0]] : X;
      const arrayY = Y[0] > Y[1] ? [Y[1], Y[0]] : Y;
      setCoordinates([...arrayX, ...arrayY]);
      setLeft(null);
      setRight(null);
    }
  };
  const resetZoom = () => {
    setX([]);
    setY([]);
    setLeft(null);
    setRight(null);
    setCoordinates([]);
  };
  const handleMouseDown = (event: CategoricalChartState) => {
    if (!event) {
      return;
    }
    const { xValue, yValue, chartX, chartY } = event;
    setX([parseFloat(xValue.toFixed(2))]);
    setY([parseFloat(yValue.toFixed(2))]);
    setLeft(true);
  };
  const handleMouseMove = (event: CategoricalChartState) => {
    if (!event) {
      return;
    }
    if (left) {
      setX([X[0], parseFloat(event.xValue.toFixed(2))]);
      setY([Y[0], parseFloat(event.yValue.toFixed(2))]);
      setRight(true);
    }
  };

  function renderText(child: string, x: number, y: number, rotate: string | 0, stroke: any, key?: any) {
    return (<text
      key={key}
      x={x}
      y={y}
      transform={`rotate(${rotate})`}
      textAnchor="middle"
      stroke={stroke}>{child}</text>);
  }

  function AxisLabel({ axisType, x, y, width, height, stroke, children }: any) {
    const isVert = axisType === "yAxis";
    const cx = isVert ? x : x + (width / 2);
    const cy = isVert ? (height / 2) + y : y + height + 20;
    const rot = isVert ? `270 ${cx} ${cy}` : 0;
    const lineHeight = 20;

    if (children.length > 1 && children.map) {
      return (<g>
        {children?.map((child: string, index: number) =>
          renderText(
            child,
            cx,
            cy + index * lineHeight,
            rot,
            stroke,
            index)
        )}
      </g>);
    }

    return renderText(children, cx, cy, rot, stroke);
  }

  function isTimeBetween9And18(ts: number) {
    const time = moment(ts*1_000);

    const startTime = moment(time).hour(9).minute(0).second(0);
    const endTime = moment(time).hour(18).minute(0).second(0);

    return time.isBetween(startTime, endTime, undefined, "[)");
  }

  return (
    <div className="col">
      <div className="col d-flex justify-content-between">
        <div>
          <button onClick={resetZoom} className="btn btn-outline-primary">
            {gettext("Reset zoom")}
          </button>
        </div>
        <DropdownDownload HTMLElementId={chartId} chartType="scatter" />
      </div>
      <div className="col py-0 my-0">
        <ResponsiveContainer id={chartId} width="100%" height={400}>
          <ScatterChart
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
            margin={{
              top: 20,
              right: 20,
              bottom: 25,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis allowDecimals={false} allowDataOverflow type="number" domain={[coordinates[0] || 0, coordinates[1] || 40]} dataKey={XKey} name="temperature" unit="°" label={props.xAxisUnit && (({ viewBox }: any) => <AxisLabel axisType={"xAxis"} {...viewBox} >{props.xAxisUnit}</AxisLabel>)} />
            <YAxis allowDecimals={false} type="number" domain={[coordinates[2] || 0, coordinates[3] || 100]} dataKey={YKey} name="humidity" unit="%" label={props.yAxisUnit && (({ viewBox }: any) => <AxisLabel axisType={"yAxis"} {...viewBox} >{props.yAxisUnit}</AxisLabel>)} />
            <Tooltip cursor={{ strokeDasharray: "1 1" }} content={({ payload, ...value }) => {
              return (
                <div className="custom-tooltip" style={{ border: "solid 1px grey", backgroundColor: "white", padding: "5px 10px" }}>
                  <p className="label my-0 py-1">{`${gettext("Capteur")} : ${props.lookupLabels[payload[0]?.payload.deveui]}`}</p>
                  <p className="label my-0 py-1">{`${gettext("Date")} : ${moment.unix(payload[0]?.payload.ts).locale(document.documentElement.lang).format("YYYY MMMM DD HH:mm")}`}</p>
                  <p className="label my-0 py-1">{`${gettext("Température")} : ${payload[0]?.payload[XKey]}`}</p>
                  <p className="label my-0 py-1">{`${gettext("Humidité")} : ${payload[0]?.payload[YKey]}`}</p>
                </div>
              );
            }} />
            {props.children}
            {
              Object.keys(lines).length && lines.map((v) => {
                const dataWithoutUnoccupied = v.data.filter((d:any) => isTimeBetween9And18(d.ts));
                return <RScatter key={`scatter-${v.label}`} label={v.label} name={v.label} data={dataWithoutUnoccupied} fill={v.color} />;
              })
            }
            {
              (left && right) && (
                <ReferenceArea x1={X[0]} x2={X[1]} y1={Y[0]} y2={Y[1]} strokeOpacity={0.3} />
              )
            }
          </ScatterChart>
        </ResponsiveContainer>
        { props.areaLegend }
      </div>
    </div >
  );
}

interface Props {
    lookupLabels: any;
    data?: SensorsData[],
    XKey: string,
    YKey?: string,
    lines?: any[];
    chartId?: string;
    children?: any;
    yAxisUnit?: string;
    xAxisUnit?: string;
    areaLegend?: any;
}
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {RootState, useAppDispatch} from "@src/store";
import {signin} from "@src/auth/core/usecases/signin.usecase";
import {AuthDomainModel} from "@src/auth/core/models/auth.domain-model";
import {useSelector} from "react-redux";

const initAuthForm = {
  defaultValues: {
    email: "",
    password: "",
  },
};

export const useSignInForm = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {register, handleSubmit, formState} = useForm<AuthDomainModel.Form>(initAuthForm);

  const authState = useSelector((state: RootState) => state.auth.signin);

  async function onSubmit(data: AuthDomainModel.Form) {
    try {
      await dispatch(signin(data)).unwrap();
      window.location.href = "/";
    } catch (e) {
      console.error(e);
    }
  }

  const isNotSubmittable = formState.isSubmitting || !formState.isValid;

  return {
    register,
    isNotSubmittable,
    onSubmit: handleSubmit(onSubmit),
    errors: formState.errors,
    requestStatus: authState.status,
    requestStatusCode: authState.statusCode,
    requestError: authState.error,
    passwordOptions: {required: t("The password is required")},
    emailOptions: {required: t("The email is required")},
    t
  };
};

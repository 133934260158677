import React, {createContext, useContext} from "react";
import {Dependencies} from "@src/app/dependencies";

const DependenciesContext = createContext<Dependencies>(
    null as any
);

type Props = {
    dependencies: Dependencies;
    children: React.ReactNode;
}

export function DependenciesProvider({dependencies, children}: Props) {
  return (
    <DependenciesContext.Provider value={dependencies}>
      {children}
    </DependenciesContext.Provider>
  );
}

export const useDependencies = () => useContext(DependenciesContext);
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {MouseEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface Option {
    label: string;
    icon: string;
    values: string[];
}

interface Props {
    type: string;
    options?: Option[];
    onSelectHandler: (event: any, sortField: string) => void;
}

export default function SortFilter({type, onSelectHandler, options}: Props) {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  if (!options) {
    options = [
      {label: t("Default"), icon: "", values: []},
      {label: t("Temperature"), icon: "fa-solid fa-arrow-up-short-wide", values: ["temperature", "increase"]},
      {label: t("Temperature"), icon: "fa-solid fa-arrow-down-wide-short", values: ["temperature", "decrease"]},
      {label: t("Co2"), icon: "fa-solid fa-arrow-up-short-wide", values: ["co2", "increase"]},
      {label: t("Co2"), icon: "fa-solid fa-arrow-down-wide-short", values: ["co2", "decrease"]},
    ];
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortingMenu = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: any) => {
    setAnchorEl(null);
  };

  return (<>
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={openSortingMenu ? "long-menu" : undefined}
      aria-expanded={openSortingMenu ? "true" : undefined}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <i className="fa-solid fa-ellipsis-vertical icon-sorting-menu"></i>
    </IconButton>
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={openSortingMenu}
      onClose={handleCloseMenu}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          data-value={option.values}
          key={option.label + index}
          selected={option.label === t("Default")}
          onClick={(e) => {
            onSelectHandler(e, type);
            setAnchorEl(null);
          }}
        >
          {option.label}
          <i className={option.icon + " icon-sorting-item"}></i>
        </MenuItem>
      ))}
    </Menu>
  </>);
}
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { tableDisplaying as storeDisplaying } from "@src/store/reducers/dashboard";
import { RootState } from "@src/store";
import { useDispatch, useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function ToggleData() {
  const displaying = useSelector((state: RootState) => state.dashboard.table.displaying);
  
  const dispatch = useDispatch();

  const handleDisplay = (_: any, newDisplay: string | null) => {
    dispatch(storeDisplaying(newDisplay));
  };

  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  return (
    <div className="text-center">
      <ToggleButtonGroup
        value={displaying}
        exclusive
        onChange={handleDisplay}
        aria-label="Select data type"
      >
        <ToggleButton id="table-temp-button" value="temperature" aria-label="Data Field Temperature">
          <i className="fa-solid fa-temperature-half"></i> {t("Temperature")}
        </ToggleButton>
        <ToggleButton id="table-co2-button" value="co2" aria-label="Data Field Co2">
          <i className="fa-solid fa-smog"></i> CO<sub>2</sub>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
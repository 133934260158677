import {useTranslation} from "react-i18next";

interface Props {
  statusCode: number | null;
}

function LoginError({statusCode}: Props) {
  const { t } = useTranslation();
  if (statusCode === null)
    return <></>;

  return (
    <>
      {
        statusCode == 401 ? (
          <div className='alert alert-danger'>
            {t("This user/password pair does not exist")}
          </div>
        ) : (
          <div className='alert alert-danger'>
            {t("An unexpected error has occurred")}
          </div>
        )
      }
    </>
  );
}

export default LoginError;
import "main";
import "./i18n_config";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import type {} from "styled-components/cssprop";
import axios, { AxiosHeaders } from "axios";
import {AppWrapper} from "@src/app/react/AppWrapper";

declare global {
  function gettext(str: string): string;
}

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.interceptors.request.use(function (config) {
  if(config.url.includes("/csrf/") || !["post", "put", "patch", "delete"].includes(config.method.toLowerCase())) {
    return config;
  }
  return axios.get("/csrf/").then((response) =>
    Object.assign<typeof config, typeof config>(config, {
      headers: new AxiosHeaders({
        ...config.headers,
        "X-CSRFToken": response.data,
      }),
    })
  );
});

const production_urls = [/^https:\/\/s-insight\.accenta\.ai\//];
const staging_urls = [/^https:\/\/insight\.accenta\.ai\//];
const currentUrl = window.location.hostname;
const environment = "local";

const initOption = {
  dsn: "https://ee4efe153bd52daa12e3fe4d1d98182e@o4508121257082880.ingest.de.sentry.io/4508138375479376",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [...production_urls, ...staging_urls],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment
};

let isMatch = production_urls.some((regex) => regex.test(currentUrl));
if (isMatch) {
  initOption.environment = "production";
} else {
  isMatch = staging_urls.some((regex) => regex.test(currentUrl));
  initOption.environment = "staging";
}

if (environment != "local") {
  Sentry.init(initOption);
}

document.addEventListener("DOMContentLoaded", () => {
  const container = createRoot(document.querySelector("#root"));

  container.render(
    <AppWrapper />
  );
});

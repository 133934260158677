import {RootState, useAppDispatch} from "@src/store";
import {useEffect, useState} from "react";
import {getCsrfToken} from "@src/auth/core/usecases/crsf-token.usecase";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";


export const useForgotPasswordPage = () => {
  const {t} = useTranslation();
  const [csrfToken, setCsrfToken] = useState(null);
  const dispatch = useAppDispatch();
  const [urlParams] = useSearchParams();

  const emailStatus = useSelector((state: RootState) => state.auth.forgotPassword.email.status);

  useEffect(() => {
    dispatch(getCsrfToken({})).then((response) => {
      setCsrfToken(response.payload);
    });
  }, []);

  return {
    csrfToken,
    urlParams,
    emailStatus,
    t
  };
};



import "@src/scss/auth/layout.scss";
import {useTranslation} from "react-i18next";

export default function Layout(props: Props) {
  const { t } = useTranslation();
  return (
    <div className="page layout-page main-body">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex">
            <div className="row wd-100p mx-auto text-center">
              <div
                className="col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p"
                id="logo-slogan"
              >
                <img
                  src={
                    process.env.STATIC_URL +
                    "img/brand/accenta-insight-white.svg"
                  }
                  className="my-auto ht-xl-80p wd-md-100p wd-xl-80p mx-auto"
                  alt="logo"
                />
                <div id="slogan">
                  <h1>{t("Let's democratize low carbon buildings")}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-5 bg-white">
            <div className="login d-flex align-items-center py-2">
              <div className="container p-0">
                <div className="row">
                  <div className="col-md-10 col-lg-10 col-xl-9 mx-auto">
                    {props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
interface Props {
  children: JSX.Element;
}
